<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <b-card
        no-body
        class="mb-2"
      >
        <b-card-header class="p-1">
          <b-card-title class="font-medium-2">
            <feather-icon
              icon="BookIcon"
              size="18"
            />
            <span class="align-middle ml-50">{{ name }}</span>
          </b-card-title>
        </b-card-header>
        <validation-observer ref="formData">
          <b-form class="p-2">
            <b-row>
              <b-col cols="6">
                <!-- refId -->
                <b-form-group
                  :label="$t('Name')"
                  label-for="register-name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    vid="name"
                    rules="required"
                  >
                    <b-form-input
                      id="register-name"
                      v-model="name"
                      name="register-name"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('Name')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <!-- refId -->
                <b-form-group
                  :label="$t('Detail')"
                  label-for="register-detail"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Detail"
                    vid="detail"
                    rules="required"
                  >
                    <b-form-input
                      id="register-detail"
                      v-model="detail"
                      name="register-detail"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('Detail')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
              >
                <!-- refId -->
                <b-form-group
                  :label="$t('Working Period')"
                  label-for="register-workingPeriod"
                >
                  <b-table
                    striped
                    responsive
                    class="mb-0"
                    :items="workingPeriod"
                    :fields="tableColumns"
                    style="height: 60vh;"
                  >
                    <template #cell(day)="data">
                      <p class="align-middle">
                        {{ $t(workingPeriod[data.index].day) }}
                      </p>
                      <p
                        v-if="workingPeriod[data.index].openTime.hour !==null && workingPeriod[data.index].openTime.minute !==null
                          && workingPeriod[data.index].closeTime.hour !==null && workingPeriod[data.index].closeTime.minute !== null
                        "
                        class="align-middle"
                      >
                        {{ $t('Moment 1') }} : ({{ timeFomat(workingPeriod[data.index].openTime.hour) }}:{{ timeFomat(workingPeriod[data.index].openTime.minute) }}-{{ timeFomat(workingPeriod[data.index].closeTime.hour) }}:{{ timeFomat(workingPeriod[data.index].closeTime.minute) }})
                      </p>
                      <p
                        v-if="workingPeriod[data.index].openTime2.hour !==null && workingPeriod[data.index].openTime2.minute !==null
                          && workingPeriod[data.index].closeTime2.hour !==null && workingPeriod[data.index].closeTime2.minute !== null
                        "
                        class="align-middle"
                      >
                        {{ $t('Moment 2') }} : ({{ timeFomat(workingPeriod[data.index].openTime2.hour) }}:{{ timeFomat(workingPeriod[data.index].openTime2.minute) }}-{{ timeFomat(workingPeriod[data.index].closeTime2.hour) }}:{{ timeFomat(workingPeriod[data.index].closeTime2.minute) }})
                      </p>
                    </template>
                    <template #cell(openTime)="data">
                      <cleave
                        id="openTime"
                        :value="valueTime('openTime', data.index)"
                        class="form-control"
                        :raw="false"
                        :options="{ time:true , timePattern: ['h','m']}"
                        placeholder="HH:MM"
                        @input="inputTime($event,'openTime', data.index)"
                      />
                    </template>
                    <template #cell(closeTime)="data">
                      <cleave
                        id="closeTime"
                        :value="valueTime('closeTime', data.index)"
                        class="form-control"
                        :raw="false"
                        :options="{ time:true , timePattern: ['h','m']}"
                        placeholder="HH:MM"
                        @input="inputTime($event,'closeTime', data.index)"
                      />
                    </template>
                    <template #cell(openTime2)="data">
                      <cleave
                        id="openTime2"
                        :value="valueTime('openTime2', data.index)"
                        class="form-control"
                        :raw="false"
                        :options="{ time:true , timePattern: ['h','m']}"
                        placeholder="HH:MM"
                        @input="inputTime($event,'openTime2', data.index)"
                      />
                    </template>
                    <template #cell(closeTime2)="data">
                      <cleave
                        id="closeTime2"
                        :value="valueTime('closeTime2', data.index)"
                        class="form-control"
                        :raw="false"
                        :options="{ time:true , timePattern: ['h','m']}"
                        placeholder="HH:MM"
                        @input="inputTime($event,'closeTime2', data.index)"
                      />
                    </template>
                    <template #cell(action)="data">
                      <b-form-checkbox
                        v-model="workingPeriod[data.index].status"
                        class="custom-control-primary"
                        name="check-button"
                        switch
                      />
                    </template>
                  </b-table>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Action button -->
            <div class="d-flex mt-2">
              <b-button
                variant="primary"
                class="mr-2"
                @click="validationForm()"
              >
                {{ $t("Submit") }}
              </b-button>
              <b-button
                type="button"
                variant="outline-secondary"
                @click="cancel"
              >
                {{ $t("Cancel") }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCardHeader,
  BCardTitle,
  BCard,
  BRow,
  BCol,
  BForm,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
  BButton,
  BOverlay,
  BTable,
} from 'bootstrap-vue'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { ref } from '@vue/composition-api'
import Cleave from 'vue-cleave-component'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import storeModule from '@/store/services/hero/partnerTemplateTime'

const STORE_MODULE_NAME = 'partnerTemplateTime'
export default {
  components: {
    Cleave,
    ValidationProvider,
    ValidationObserver,
    BCardHeader,
    // eslint-disable-next-line vue/no-unused-components
    BCardTitle,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    BButton,
    BOverlay,
    BTable,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show: false,
      dataId: null,
      name: '',
      detail: '',
      workingPeriod: [
      ], // ตารางการจัดส่ง
      required,
    }
  },
  computed: {
    tableColumns() {
      return [
        { key: 'day', label: this.$t('Day'), sortable: false },
        { key: 'openTime', label: this.$t('OpenTime'), sortable: false },
        { key: 'closeTime', label: this.$t('CloseTime'), sortable: false },
        { key: 'openTime2', label: this.$t('OpenTime2'), sortable: false },
        { key: 'closeTime2', label: this.$t('CloseTime2'), sortable: false },
        { key: 'action', label: this.$t('Action'), sortable: false },
      ]
    },
    // eslint-disable-next-line vue/return-in-computed-property
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)

    if (this.$route.params.id !== 'add') {
      const obj = {
        id: this.$route.params.id,
      }

      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/view`, obj)
      // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false
          const respData = result.data.data
          this.selectedData(respData)
        // console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
          // eslint-disable-next-line no-unused-vars
        }).catch(error => {
          this.show = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    } else {
      const day = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
      for (let index = 0; index < day.length; index += 1) {
        this.workingPeriod.push({
          day: day[index],
          openTime: {
            hour: null,
            minute: null,
          },
          closeTime: {
            hour: null,
            minute: null,
          },
          openTime2: {
            hour: null,
            minute: null,
          },
          closeTime2: {
            hour: null,
            minute: null,
          },
          status: false,
        })
      }
    }
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
  },
  setup() {
    return { STORE_MODULE_NAME }
  },
  methods: {
    valueTime(type, index) {
      if (type === 'openTime') {
        let textHour = ''
        let textMinute = ''
        const { hour, minute } = this.workingPeriod[index].openTime
        if (hour !== null && minute !== null) {
          if (hour.toString().length === 2) {
            textHour = hour.toString()
          } else {
            textHour = `0${hour.toString()}`
          }
          if (minute.toString().length === 2) {
            textMinute = minute.toString()
          } else {
            textMinute = `0${minute.toString()}`
          }
          return `${textHour}:${textMinute}`
        }
        return ''
      }
      if (type === 'closeTime') {
        let textHour = ''
        let textMinute = ''
        const { hour, minute } = this.workingPeriod[index].closeTime
        if (hour !== null && minute !== null) {
          if (hour.toString().length === 2) {
            textHour = hour.toString()
          } else {
            textHour = `0${hour.toString()}`
          }
          if (minute.toString().length === 2) {
            textMinute = minute.toString()
          } else {
            textMinute = `0${minute.toString()}`
          }
          return `${textHour}:${textMinute}`
        }
        return ''
      }
      if (type === 'openTime2') {
        let textHour = ''
        let textMinute = ''
        const { hour, minute } = this.workingPeriod[index].openTime2
        if (hour !== null && minute !== null) {
          if (hour.toString().length === 2) {
            textHour = hour.toString()
          } else {
            textHour = `0${hour.toString()}`
          }
          if (minute.toString().length === 2) {
            textMinute = minute.toString()
          } else {
            textMinute = `0${minute.toString()}`
          }
          return `${textHour}:${textMinute}`
        }
        return ''
      }
      if (type === 'closeTime2') {
        let textHour = ''
        let textMinute = ''
        const { hour, minute } = this.workingPeriod[index].closeTime2
        if (hour !== null && minute !== null) {
          if (hour.toString().length === 2) {
            textHour = hour.toString()
          } else {
            textHour = `0${hour.toString()}`
          }
          if (minute.toString().length === 2) {
            textMinute = minute.toString()
          } else {
            textMinute = `0${minute.toString()}`
          }
          return `${textHour}:${textMinute}`
        }
        return ''
      }
      return ''
    },
    inputTime(data, type, index) {
      if (data.length === 5) {
        console.log(true)
        if (type === 'openTime') {
          const time = data.split(':')
          // eslint-disable-next-line radix
          this.workingPeriod[index].openTime.hour = parseInt(time[0])
          // eslint-disable-next-line radix
          this.workingPeriod[index].openTime.minute = parseInt(time[1])
        } else if (type === 'closeTime') {
          const time = data.split(':')
          // eslint-disable-next-line radix
          this.workingPeriod[index].closeTime.hour = parseInt(time[0])
          // eslint-disable-next-line radix
          this.workingPeriod[index].closeTime.minute = parseInt(time[1])
        } else if (type === 'openTime2') {
          const time = data.split(':')
          // eslint-disable-next-line radix
          this.workingPeriod[index].openTime2.hour = parseInt(time[0])
          // eslint-disable-next-line radix
          this.workingPeriod[index].openTime2.minute = parseInt(time[1])
        } else if (type === 'closeTime2') {
          const time = data.split(':')
          // eslint-disable-next-line radix
          this.workingPeriod[index].closeTime2.hour = parseInt(time[0])
          // eslint-disable-next-line radix
          this.workingPeriod[index].closeTime2.minute = parseInt(time[1])
        }
      }
    },
    timeFomat(n) {
      if (n !== null) {
        if (n.toString().length === 1) {
          return `0${n.toString()}`
        }
      }
      return n
    },
    selectedData(data) {
      this.dataId = data._id
      this.name = data.name
      this.detail = data.detail
      this.workingPeriod = data.workingPeriod
    },
    validationForm() {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to save')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'success',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.$refs.formData.validate().then(success => {
              if (success) {
                const obj = {
                  id: this.dataId,
                  name: this.name,
                  detail: this.detail,
                  workingPeriod: this.workingPeriod,
                }
                if (obj.id !== null) {
                  this.show = true

                  store
                    .dispatch(`${STORE_MODULE_NAME}/update`, obj)
                    .then(result => {
                      console.log('fetch Success : ', result)
                      this.show = false
                      this.$router.push({ path: '/hero/partner/template/time' })
                      this.$toast({
                        component: ToastificationContent,
                        position: 'bottom-right',
                        props: {
                          title: this.$t('Success'),
                          icon: 'CheckIcon',
                          variant: 'success',
                          text: this.$t('Success'),
                        },
                      })
                    }).catch(error => {
                      console.log('fetchUsers Error : ', error)
                      this.show = false

                      this.$toast({
                        component: ToastificationContent,
                        position: 'bottom-right',
                        props: {
                          title: this.$t('Error'),
                          icon: 'ErrorIcon',
                          variant: 'danger',
                          text: this.$t(error.response.data.message),
                        },
                      })
                    })
                } else {
                  this.show = true

                  store
                    .dispatch(`${STORE_MODULE_NAME}/add`, obj)
                    .then(result => {
                      console.log('fetch Success : ', result)
                      this.show = false

                      this.$router.push({ path: '/hero/partner/template/time' })

                      this.$toast({
                        component: ToastificationContent,
                        position: 'bottom-right',
                        props: {
                          title: this.$t('Success'),
                          icon: 'CheckIcon',
                          variant: 'success',
                          text: this.$t('Success'),
                        },
                      })
                    }).catch(error => {
                      console.log('fetchUsers Error : ', error)
                      this.show = false

                      this.$toast({
                        component: ToastificationContent,
                        position: 'bottom-right',
                        props: {
                          title: this.$t('Error'),
                          icon: 'ErrorIcon',
                          variant: 'danger',
                          text: this.$t(error.response.data.message),
                        },
                      })
                    })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t('Error Validator'),
                  },
                })
              }
            })
          }
        })
    },
    cancel() {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to cancel')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'danger',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.$router.push({ path: '/hero/partner/template/time' })
          }
        })
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
